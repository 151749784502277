export default function Loader(){
  let clases = {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor:"rgba(0, 0, 0, 0.25)",
    zIndex: 1000,
    textAlign: "center"
  }
  return(
    <div className='d-flex justify-content-center align-items-center' style={clases}>
      <span className="spinner-border " style={{width: '4rem', height: '4rem', color:"green"}} role="status" aria-hidden="true"></span>
    </div>
  )
}