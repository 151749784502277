import { backEndUrl, originUrl } from "./env"
import { getHash, setHash } from "./hash"

// Funcion para la definicion de los select de las empresas ya existentes
export function getEmpresas( setEmpresas ){
  let formData = new FormData();
  formData.append("proceso",21)
  
  fetch( backEndUrl ,{
    method : "POST",
    body : formData,
  }).catch(e=>{ console.log(e)})
    .then(res => res.json())
    .then(async e=>{
      if(e['state']){
        let info = e['info'];
        setEmpresas(JSON.parse(await getHash(info)));
      }
    })
    .catch(e=>{ console.log(e) })
}

// Funcion para consulta de usuarios ya existentes
export function getUsers( setUsers ){
  let formData = new FormData();
  formData.append("proceso",31)
  
  fetch( backEndUrl ,{
    method : "POST",
    body : formData,
  }).catch(e=>{ console.log(e)})
    .then(res => res.json())
    .then(async e=>{
      if(e['state']){
        let info = e['info'];
        setUsers(JSON.parse(await getHash(info)));
      }
    })
    .catch(e=>{ console.log(e) })
}

// Funcion para la creacion y actualizacion del usuario
export function creaUsuario( formData, setLoad, setNotif, setUsersTable, clearLbl ){
  // formData.append("proceso",32);
  
  fetch( backEndUrl ,{
    method : "POST",
    body : formData,
  }).catch(e=>{ console.log(e)})
    .then(res => res.json())
    .then(e=>{
      if(e.state){
        setNotif(true,e.msg);
        clearLbl()
        setTimeout(()=>{
          getUsers(setUsersTable)
          setLoad(false)
        },1000)
      }
      else{
        setNotif(true,e.msg);
      }
    })
    .catch(e=>{ console.log(e) })
}

// proceso para eliminacion de usuario y restaurar a contraseña por defecto.
export function accionesUsuario( proc, u, setLoad, setNotif, setUsersTable=null ){
  let formData = new FormData();
  formData.append("proceso",proc);
  formData.append("u",u);
  
  fetch( backEndUrl ,{
    method : "POST",
    body : formData,
  }).catch(e=>{ console.log(e)})
  .then(res => res.json())
  .then(e=>{
    if(e.state){
      setNotif(true,e.msg);
      if(setUsersTable==null)
        setLoad(false)
      else
        setTimeout(()=>{
          getUsers(setUsersTable)
          setLoad(false)
        },1000)
    }
    else setNotif(true,e.msg);
  })
  .catch(e=>{ console.log(e) })
}

export function accionesEmpresa( proc, id, setLoad, setNotif, setTableInfo=null ){
  let formData = new FormData();
  formData.append("proceso",proc);
  formData.append("id",id);
  
  fetch( backEndUrl ,{
    method : "POST",
    body : formData,
  }).catch(e=>{ console.log(e)})
    .then(res => res.json())
    .then(e=>{
      if(e.state){
        setNotif(true,e.msg);
        if(setTableInfo==null)
          setLoad(false)
        else
          setTimeout(()=>{
            getEmpresas(setTableInfo)
            setLoad(false)
          },1000)
      }
      else{
        setNotif(true,e.msg);
      }
    }).catch(e=>{ console.log(e) })
}

// Para la creacion de empresas
export async function creaEmpresa( info, setLoad, setNotif, setTable, clearLbl ){
  let formData = new FormData();
  formData.append("ref",info.ref);
  formData.append("ne",info.ne);
  formData.append("url",JSON.stringify(await setHash(info.url)));
  formData.append("proceso",info.proceso);
  if(info.id!=null) formData.append("id",info.id)
  
  fetch( backEndUrl ,{
    method : "POST",
    body : formData,
  }).catch(e=>{ console.log(e)})
    .then(res => res.json())
    .then(e=>{
      if(e.state){
        setNotif(true,e.msg);
        clearLbl()
        setTimeout(()=>{
          getEmpresas(setTable)
          setLoad(false)
        },1000)
      }
      else{
        setNotif(true,e.msg);
      }
    }).catch(e=>{ console.log(e) })
}

// Para setear la nueva contraseña, tras iniciar sesion por primera vez
export function setNewPass(user, newPass, setNotif, setDisable, redir,clrUser){
  let formData = new FormData();
  formData.append("proceso",36)
  formData.append("u",user)
  formData.append("p",newPass)

  fetch( backEndUrl ,{
    method : "POST",
    body : formData,
  }).catch(e=>{ console.log(e)})
    .then(res=>res.json())
    .then(e=>{
      if(e.state)
        setDisable(true)
      setNotif(true, e.msg);
      clrUser("")
      setTimeout(() => {
        redir();
      }, 2000);
    }).catch(e=>{ console.log(e) })
}
