import React,{ useState, useEffect } from "react";
import { useNavigate  } from 'react-router-dom'
import Loader from "../loader/loader";
import Alert from "../alert/alert";
import {LlaoLogoL, LlaoLogoR, prodImg} from "../../images/images"
import {backEndUrl} from "../../functions/env"
import { setHash, getHash } from "../../functions/hash";

export default function Login(props){
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true)
  const [load, setLoad] = useState(false);
  const [notif, setNotif] = useState(false);
  const [msgNotif, setMsgNotif] = useState("");
  
  let his = useNavigate ()

  useEffect(async ()=>{
    let localCred = JSON.parse(localStorage.getItem('LlaoPBIAuth'))
    if(localCred && localCred.auth && ((Date.now() - localCred.time)/60000 ) < 60){
      props.setAuth(localCred.auth);
      let url = await getHash(localCred.url);
      
      if(/^\/\w+/.test(url)){
        props.setUri(url);
        his(url);
      }
      else{
        props.setUri(localCred.url);
        his("/dashboard");
      }
    }
  },[])

  useEffect(()=>{
    // if(pwd !== "" && /^\w{4,15}@\w{3,10}$/.test(user))
    if(pwd !== "" && /^\w+([-|\.]\w+)?@\w{3,30}\.\w{2,4}(\.\w{2,4})?$/.test(user))
      setDisabledButton(false)
    else setDisabledButton(true)
  },[pwd,user])

  var authenticate = async (e) => {
    e.preventDefault();
    setLoad(true);
    let loginData = new FormData();
    loginData.append("proceso",0);
    let us = await setHash(user);
    let ps = await setHash(pwd);
    if(us !== null && ps !== null ){
      loginData.append("u",JSON.stringify(us));
      loginData.append("p",JSON.stringify(ps));
      
      fetch(backEndUrl,{
        method : "POST",
        body : loginData,
      }).catch(e=>{
        console.log(e);
      }).then(res => res.json())
        .then(async e=> {
          setLoad(false);
          if(e.state){
            props.setAuth(true);
            if(e.tp === '1'){
              // props.setTmpPass(true)
              props.setUser(e.u)
              his("/auth");
            }
            else{
              localStorage.setItem('LlaoPBIAuth', JSON.stringify({
                auth : true,
                url : e.info,
                time : Date.now()
              }));
              let destino = await getHash(e.info)
              if(/^\/\w+/.test(destino)){
                his(destino);
                props.setUri(destino);
              }
              else{
                props.setUri(e.info);
                his("/dashboard");
              }
            }
          }
          else{
            setNotif(true);
            setMsgNotif(e.msg);
          }
        })
      }
      else{
        setNotif(true);
        setMsgNotif("Hubo un error con el servidor, reintente de nuevo mas tarde");
        setLoad(false);
      }
  }

  // var back = () =>{
  //   console.log(his);
  //   his.goBack();
  // }

  return(
    <>
    {load?<Loader/>:""}
    {notif?<Alert active={notif} msg={msgNotif} close={setNotif}/>:""}
    <div className="container-fluid h-100 position-relative d-flex flex-column">
      <div className="row d-block d-md-none ">
        <div className="col-12 position-relative mt-5 pt-5">
          <img src={LlaoLogoL} alt="Logo Llaollao El Salvador" className="position-absolute top-50 start-50 translate-middle "/>
        </div>
      </div>
      <div className="row flex-grow-1 flex-shrink-1">
        <div className="col-sm-12 col-md-6 col-lg-4 position-relative">
          <div className="position-absolute top-50 start-50 translate-middle w-100">
            <div className="container">
              {/* <div className="col-10 offset-1"> */}
                <form className="py-5 px-4 rounded-3 bg-main">
                  <h1 className="BreeBold">Inicio de Sesión</h1>
                  <div className="mb-3">
                    <label htmlFor="userInput" className="form-label mt-5">Usuario</label>
                    <input type="text" className="form-control BreeLight" id="userInput" placeholder="Usuario@Empresa" value={user} onChange={e=>setUser(e.target.value)}/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="PasswordInput" className="form-label">Contraseña</label>
                    <div className="input-group mb-3">
                      <input type={showPwd?"text":"password"} className="form-control BreeLight" id="PasswordInput" onChange={e=>setPwd(e.target.value)} placeholder="secret123" value={pwd}/>
                      <span className="input-group-text" id="basic-addon1" onClick={()=>setShowPwd(!showPwd)}>
                        <i className={showPwd?"bi-eye-slash ":"bi-eye"}/>
                      </span>
                    </div>
                  </div>
                  <div className="d-grid gap-2 mt-5">
                    <button className="btn bg-white" onClick={e=>authenticate(e)} disabled={disabledButton}>Inicia Sesion</button>
                  </div>
                  {/* <button className="btn float-end mb-4" onClick={e=>test(e)} >test</button> */}
                </form>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="d-none d-md-block col-md-6 col-lg-8 position-relative">
          <div className="position-absolute top-50 start-50 translate-middle w-100">
            <div className="container">
              <div className="row">
                <div className="offset-1 col-10 text-end">
                  <img src={LlaoLogoR} alt="Logo Llaollao El Salvador" className="" height=""/>
                </div>
                {/* <div className="col-6">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi nobis, voluptates porro doloribus praesentium placeat impedit quasi, ullam aliquam voluptatibus, laborum nulla? Magni sunt illo assumenda necessitatibus quis quibusdam aspernatur.
                </div> */}
                <div className="col-12">
                  <img src={prodImg} alt="Productos Llaollao" className="img-fluid"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
  
}