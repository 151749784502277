import { useEffect,useState } from "react";
import { getEmpresas, creaEmpresa } from '../../../../functions/db'

export default function Empresas(props){
  
  const [nuevaEmpresa, setNuevaEmpresa] = useState("");
  const [urlEmpresa, setUrlEmpresa] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [edit, setEdit] = useState(false)
  const [actualEdit, setActualEdit] = useState(0);

  useEffect(()=>{
    getEmpresas(props.setEmpresasList)
  },[])

  useEffect(()=>{
    if(nuevaEmpresa!="" && urlEmpresa!="")
      setDisabledButton(false)
    else setDisabledButton(true);
  },[nuevaEmpresa,urlEmpresa])

  var handleCreateEmpresa = (e,proc) =>{
    e.preventDefault();
    props.setLoad(true);
    let info = {
      ne:nuevaEmpresa,
      url:urlEmpresa,
      proceso:proc,
      id:proc==23?props.empresasList[actualEdit].IDDASHBOARD:null
    }
    creaEmpresa(info, props.setLoad, notif, props.setEmpresasList, clearLbl);
  }

  var handleEditBtn = e =>{
    setEdit(true);
    let editEmpresa = props.empresasList[e];
    setActualEdit(e); 
    setNuevaEmpresa(editEmpresa.EMPRESA)
    setUrlEmpresa(editEmpresa.URLDASHBOARD)
  }

  var handleCancelEditBtn = () =>{
    setEdit(false);
    clearLbl();
  }
  
  var handleDeleteEmpresa = e => {
    let actualEmpresa = props.empresasList[e];
    props.setModalTxt("Desea eliminar a "+actualEmpresa.EMPRESA+" definitivamente?");
    props.setModalBtnInfo({'proc':24, 'id':actualEmpresa.IDDASHBOARD,'setEmpresasList':props.setEmpresasList,'from':2})
  }

  var notif = (b,m) =>{
    props.setNotif(b)
    props.setMsgNotif(m)
  }
  var clearLbl = () =>{
    setNuevaEmpresa("");
    setUrlEmpresa("");
    setEdit(false);
  }
  
  return(
    <>
    <div className="row">
      <div className="col-sm-12 col-md-4">
      <form className="m-3">
        <label htmlFor="inputNuevaEmpresa" className="form-label">Nombre de Empresa</label>
        <input type="text" className="form-control mb-3" aria-label="Usuario" id="inputNuevaEmpresa" onChange={e=>setNuevaEmpresa(e.target.value)} value={nuevaEmpresa}/>
        <label htmlFor="inputUrl" className="form-label">Url de Dashboard</label>
        <textarea id="inputUrl" className="form-control mb-3" onChange={e=>setUrlEmpresa(e.target.value)} value={urlEmpresa}/>
          
        {edit?
        <>
        <div className="row">
          <button className="btn bg-main my-1 col-5" onClick={e=>handleCreateEmpresa(e,23)}>Guardar</button>
          <button className="btn bg-gray my-1 offset-2 col-5" onClick={handleCancelEditBtn}>Cancelar</button>
        </div>
        </>:
        <button className="btn bg-main" onClick={e=>handleCreateEmpresa(e,22)} disabled={disabledButton}>Crear Empresa</button>
        }
      </form>
      </div>
      <div className="col-sm-12 col-md-8">
        <div className="table-of">
        <table className="table table-striped ">
          <thead className="sticky-sm-top">
            <tr className="bg-white">
              <th scope="col">#</th>
              <th scope="col">Dashboard</th>
              <th scope="col">URL</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody >
            {props.empresasList.map((e,i)=>{
              // console.log(e)
              return(
                <tr key={i}>
                  <th scope="row">{i+1}</th>
                  <td>{e.DASHBOARD}</td>
                  <td className="text-truncate" style={{maxWidth:"300px"}}>{e.URLDASHBOARD}</td>
                  <td>
                    <div className="d-flex justify-content-around">
                      <button className="btn bg-transparent p-0" onClick={()=>handleEditBtn(i)}>
                        <i className="bi-pencil-square"></i>
                      </button>
                      <button className="btn bg-transparent p-0" onClick={()=>handleDeleteEmpresa(i)} data-bs-toggle="modal" data-bs-target="#confirmModal">
                        <i className="bi-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        </div>
      </div>
    </div>
    </>
  )
}