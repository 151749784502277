/*
hash.js
Genera el cifrado de la informacion que se transmite entre el servidor y la aplicacion
*/

import { backEndUrl } from "./env";

async function getDic(){
  let req = new FormData();
  req.append("dicc",true);
  let diccionario = await fetch(backEndUrl+"consum/dicc.php",{
    method: "POST",
    body: req
  }).then(r=>r.json())
  .catch(()=> {return null})

  return diccionario;  
}

function first(x){
  return(3 * (Math.pow(x,4/3) - Math.exp(4/3)) / 4);
}

function second(y){
  return Math.round(Math.pow( (4*y/3) + Math.exp(4/3) ,3/4));
}

async function getNewDic(cl){

  let diccionario = await getDic();
  let newDic = [];
  for (let i = cl; i >= 0; i--) 
    for (let j = i; j < diccionario.length; j+=cl) {
      let flag = true;
      newDic.forEach(e=>{
        if(diccionario[j]==e)  flag = false;
      })
      if(flag) newDic.push(diccionario[j])
    }
  return newDic;
}

export async function setHash( input = "Texto de Prueba a Encriptar" ){

  let diccionario = await getDic();
  if(diccionario === null) return null
  var time = new Date()

  var strInput = input.split("");
  var strOutput = "";
  var llave = Math.round(time.getMilliseconds()*2/diccionario.length,0);
  llave = llave<3 ? llave+3 : llave;
  if(llave%2==0) time.getDay()%2==0 ? llave++ : llave--;
  
  let newDic = await getNewDic(llave);

  strInput.forEach(e=>{
    strOutput+= newDic[diccionario.indexOf(e)];
  })
  return [strOutput, first(llave)];
}

export async function getHash(data = ["gP3K6SuPSY54PQvSvSlM 58qKv5", 40.607804856990256]){
  let input = data[0]
  let cl = data[1]

  let diccionario = await getDic();
  if(diccionario === null) return null

  var strInput = input.split("");
  var strOutput = "";

  var llave = second(cl);
  let newDic = await getNewDic(llave);
  
  strInput.forEach(e=>{
    strOutput+= diccionario[newDic.indexOf(e)];
  })
  return strOutput;
}
