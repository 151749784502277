import {useState, useEffect} from 'react'
import Login from './components/logIn/Login'
import Dashboard from './components/dashboard/dashboard'
import Admin from './components/admin/admin'
import NewPass from './components/newpass/newpass'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import {AEInfo} from "./images/images"

export default function App() {
  const [uri, setUri] = useState('');
  const [auth, setAuth] = useState(false);
  const [comp, setComp] = useState('');
  const [user, setUser] = useState('');
  // const [tmpPass, setTmpPass] = useState(false);


  return (
    <>
      <Router>
        <div className = "h-100 position-relative">
          <Routes>
            <Route path = "/admin" element = {
              <Admin 
                setAuth = {setAuth} 
                auth = {auth}
              />
            }/>
            <Route path = "/auth" element = {
              <NewPass 
                user = {user}
                auth = {auth}
                setUser={setUser}
              />
            }/>
            <Route path = "/dashboard" element = {
                <Dashboard 
                  url = {uri} 
                  comp = {comp} 
                  user = {user} 
                  auth = {auth}
                  setAuth = {setAuth} 
                />
            }/>
            <Route path = "/" element = {
              <Login 
                user = {user}
                auth = {auth}
                setUser = {setUser}
                setAuth = {setAuth}
                setUri = {setUri}
                setComp = {setComp}
                // setTmpPass = {setTmpPass}
              />
            }/>
          </Routes>
  
          {typeof(uri) == 'object'?'':
            <footer className = "w-100 position-fixed bottom-0">
              <a href = "https://aeinformatica.net" target = "_blank" rel = "noreferrer">
                  <img src = {AEInfo} alt = "AE Informatica S.A. de C.V." height = "30" className = "float-end me-3 my-1"/>              
              </a>
            </footer>
          }
        </div>
      </Router>
      
      
    </> 
  );
}
