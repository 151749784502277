import { useNavigate } from "react-router";
import { useEffect } from "react";
import { AEInfo } from "../../images/images"
import { backEndUrl } from "../../functions/env";

export default function Dashboard(props){

  let his = useNavigate();
  
  useEffect(()=>{
    if(!props.auth) his("/")

    setTimeout(()=>{
        document.getElementById('renewBtn').click()
      },3540000)
  },[])

  var logOut = e =>{
    e.preventDefault();
    localStorage.removeItem("LlaoPBIAuth");
    props.setAuth(false);
    his("/")
  }

  let urlFrame = backEndUrl+"index.php?r="+JSON.stringify(props.url);

  var renewCred = () => {
    let localCred = JSON.parse(localStorage.getItem('LlaoPBIAuth'))
    localStorage.removeItem("LlaoPBIAuth");
    localStorage.setItem('LlaoPBIAuth', JSON.stringify({
      auth : localCred.auth,
      url : localCred.url,
      time : Date.now()
    }));
  }

  return(
    <>
    <div className="d-flex flex-column h-100">
      <nav className="navbar bg-white p-0">
        <div className="container-fluid">
          <a href="https://aeinfonformatica.net">
            <img src={AEInfo} alt="AEInfonformatica SA de CV" height="25"/>
          </a>
          <ul className="navbar-nav ">
            <li className="nav-item">
              <button className="btn p-1" onClick={e=>logOut(e)}><i className="bi-box-arrow-left"></i> Cerrar</button>
              <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#renewAuth" id='renewBtn'>open</button>
            </li>
          </ul>
        </div>
      </nav>

      {/* <!-- Modal --> */}
      <div className="modal fade" id="renewAuth" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="renewAuthLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="renewAuthLabel">Tiempo de sesion</h5>
            </div>
            <div className="modal-body px-5 mx-5">
              El tiempo de sesión expirará pronto<br/>¿Desea extender la sesión?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" className="btn bg-main" onClick={renewCred} data-bs-dismiss="modal">Extender</button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-grow-1 flex-shrink-1 position-relative" >
        <iframe width="100%" height='100%' src={urlFrame} frameBorder="0" title="dashboard" className="position-absolute"></iframe>
      </div>
    </div>
    </>
  )
}