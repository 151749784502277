import { useEffect,useState } from "react";
import { getEmpresas, getUsers, creaUsuario } from '../../../../functions/db'
// import { backEndUrl } from "../../../../functions/env";

export default function Usuarios(props){
  
  const [newUser, setNewUser] = useState("");
  const [userEmpresa, setUserEmpresa] = useState(-10);
  const [disabledButton, setDisabledButton] = useState(true);
  const [usersTable, setUsersTable] = useState([]);
  const [edit, setEdit] = useState(false)
  const [actualEdit, setActualEdit] = useState(0);

  useEffect(()=>{
    getEmpresas(props.setEmpresasList)
    getUsers(setUsersTable)
  },[])

  useEffect(()=>{
    if(/^\w+([-|\.]\w+)?@\w{3,30}\.\w{2,4}(\.\w{2,4})?$/.test(newUser) && userEmpresa!=-10)
      setDisabledButton(false)
    else setDisabledButton(true);
  },[newUser,userEmpresa])

  var handleCreateUser = (e,proc) =>{
    e.preventDefault();
    props.setLoad(true);
    let form = new FormData();
    form.append("u",newUser);
    form.append("e",userEmpresa);
    form.append("proceso",proc);
    if(proc==33) form.append("id",usersTable[actualEdit].IDUSUARIO)
    creaUsuario(form, props.setLoad, notif, setUsersTable, clearLbl);
  }

  var handleEditBtn = e =>{
    setEdit(true);
    let currentUser = usersTable[e];
    setActualEdit(e); 
    setNewUser(currentUser.USUARIO)
    setUserEmpresa(currentUser.IDDASHBOARD)
  }

  var handleCancelEditBtn = () =>{
    setEdit(false);
    clearLbl();
  }

  var handleResetPsw = e => {
    e.preventDefault()
    props.setModalTxt("Desea restaurar contraseña de "+usersTable[actualEdit].USUARIO+" a 'Llaollao22!'?");
    props.setModalBtnInfo({'proc':35, 'u':usersTable[actualEdit].IDUSUARIO,'setUsersTable':null, from:3})
    setEdit(false);
    clearLbl();
  }
  
  var handleDeleteUser = e => {
    let currentUser = usersTable[e];
    props.setModalTxt("Desea eliminar a "+currentUser.USUARIO+" definitivamente?");
    props.setModalBtnInfo({'proc':34, 'u':currentUser.IDUSUARIO,'setUsersTable':setUsersTable, from:3})
  }

  var notif = (b,m) =>{
    props.setNotif(b)
    props.setMsgNotif(m)
  }
  var clearLbl = () =>{
    setNewUser("");
    setUserEmpresa(-10);
    setEdit(false)
  }
  
  return(
    <>
    <div className="row">
      <div className="col-sm-12 col-md-4">
      <form className="m-3">
        <label htmlFor="inputUser" className="form-label">Usuario</label>
        <input type="text" className="form-control mb-3" aria-label="Usuario" id="inputUser" onChange={e=>setNewUser(e.target.value)} value={newUser}/>
        <label htmlFor="inputEmpresa" className="form-label">Empresa</label>
        <select id="inputEmpresa" className="form-select mb-3" onChange={e=>setUserEmpresa(e.target.value)} value={userEmpresa}>
          <option defaultChecked value="-10">Seleccione la Empresa...</option>
          {props.empresasList.map(e=>{
            return <option value={e.IDDASHBOARD} key={e.IDDASHBOARD}>{e.DASHBOARD}</option>
          })}
        </select>
        {edit?
        <>
        <div className="row">
          <button className="btn bg-main my-1 col-12" onClick={e=>handleResetPsw(e)} data-bs-toggle="modal" data-bs-target="#confirmModal">
            Restaurar Contraseña
          </button>
          <button className="btn bg-main my-1 col-5" onClick={e=>handleCreateUser(e,33)}>Guardar</button>
          <button className="btn bg-gray my-1 offset-2 col-5" onClick={handleCancelEditBtn}>Cancelar</button>
        </div>
        </>:
        <button className="btn bg-main" onClick={e=>handleCreateUser(e,32)} disabled={disabledButton}>Crear Usuario</button>
        }
      </form>
      </div>
      <div className="col-sm-12 col-md-8">
        <div className="table-of">
        <table className="table table-striped ">
          <thead className="sticky-sm-top">
            <tr className="bg-white">
              <th scope="col">#</th>
              <th scope="col">Usuario</th>
              <th scope="col">Dashboard</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody >
            {usersTable.map((e,i)=>{
              return(
                <tr key={i+e.IDUSUARIO}>
                  <th scope="row">{i+1}</th>
                  <td>{e.USUARIO}</td>
                  <td>{e.DASHBOARD}</td>
                  <td>
                    <div className="d-flex justify-content-around">
                      <button className="btn bg-transparent p-0" onClick={()=>handleEditBtn(i)}>
                        <i className="bi-pencil-square"></i>
                      </button>
                      <button className="btn bg-transparent p-0" onClick={()=>handleDeleteUser(i)} data-bs-toggle="modal" data-bs-target="#confirmModal">
                        <i className="bi-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        </div>
      </div>
    </div>
    </>
  )
}