import { useState,useEffect } from "react";
import Loader from "../loader/loader";
import Alert from "../alert/alert";
import { LlaoLogoL } from "../../images/images";
import Usuarios from "./components/usuarios/usuarios";
import Empresas from "./components/dashboards/dashboards"
import { accionesUsuario, accionesEmpresa } from "../../functions/db";
import { useNavigate  } from "react-router-dom";


export default function Admin(props){
  const [load, setLoad] = useState(false);
  const [notif, setNotif] = useState(false);
  const [msgNotif, setMsgNotif] = useState("");
  const [modalTxt, setModalTxt] = useState("");
  const [modalBtnInfo, setModalBtnInfo] = useState({});
  const [empresasList, setEmpresasList] = useState([])

  let his = useNavigate ();

  useEffect(()=>{
    if(!props.auth) his("/")
  },[])

  var handleModal = () => {
    setLoad(true)
    // console.log(modalBtnInfo)
    if(modalBtnInfo.from == 2)
      accionesEmpresa(modalBtnInfo.proc, modalBtnInfo.id, setLoad, setNot, setEmpresasList)
    if(modalBtnInfo.from == 3)
      accionesUsuario(modalBtnInfo.proc, modalBtnInfo.u, setLoad, setNot, modalBtnInfo.setUsersTable)
  }
  
  var setNot = (b,m) =>{
    setNotif(b)
    setMsgNotif(m)
  }
  
  var logOut = e =>{
    e.preventDefault();
    localStorage.removeItem("LlaoPBIAuth");
    props.setAuth(false);
    his("/")
  }

  return (
  <>
  {load?<Loader/>:""}
  <div className="position-absolute top-50 start-50 translate-middle w-100">
    <img src={LlaoLogoL} alt="Logo images Salvador" className="position-absolute top-0 start-50 translate-middle"/>
      <div className="row container-fluid mt-4">
        <div className="col-sm-12 offset-md-1 col-md-10 rounded-3 bg-white p-5">
          <nav>
            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <button className="nav-link active" id="users-tab" data-bs-toggle="tab" data-bs-target="#users" role="tab" aria-controls="users" aria-selected="true">Usuarios</button>
              <button className="nav-link" id="empresas-tab" data-bs-toggle="tab" data-bs-target="#empresas" role="tab" aria-controls="empresas" aria-selected="false">Empresas</button>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="users" role="tabpanel" aria-labelledby="users-tab">
              <Usuarios setLoad={setLoad} setMsgNotif={setMsgNotif} setNotif={setNotif} setModalTxt={setModalTxt} setModalBtnInfo={setModalBtnInfo} setEmpresasList={setEmpresasList} empresasList={empresasList}/>
            </div>
            <div className="tab-pane fade" id="empresas" role="tabpanel" aria-labelledby="empresas-tab">
              <Empresas setLoad={setLoad} setMsgNotif={setMsgNotif} setNotif={setNotif} setModalTxt={setModalTxt} setModalBtnInfo={setModalBtnInfo} setEmpresasList={setEmpresasList} empresasList={empresasList}/>
            </div>
          </div>
        </div>
      </div>
  </div>

  <Alert active={notif} msg={msgNotif} close={setNotif}/>
  <button className="position-absolute top-0 end-0 btn me-2 mt-2 bg-gray" onClick={logOut}><i className="bi bi-box-arrow-left"></i> Salir</button>
    
    {/* <!-- Modal --> */}
    <div className="modal fade" id="confirmModal" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Desea realizar la accion</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {modalTxt}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn bg-gray" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" className="btn bg-main" data-bs-dismiss="modal" onClick={handleModal}>Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}