/*
  Alert(
    active : boolean -> Para mostrar u ocultar el componente
    msg : string -> Mensaje a mostrar en componente
    close : stateFunction() -> Funcion para asignar propiedad 'active' en true o false
  )
*/

export default function Alert(props){
  return(
    <div className="position-absolute top-0 end-0">
      <div className={props.active?"alert alert-warning alert-dismissible fade show":"alert alert-warning alert-dismissible fade"} role="alert">
        {props.msg}
        <button type="button" className="btn-close" onClick={()=>props.close(false)} aria-label="Close"></button>
      </div>
    </div>
  )
}