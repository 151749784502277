import Loader from "../loader/loader"
import Alert from "../alert/alert"
import { useState, useEffect } from "react";
import {LlaoLogoL} from "../../images/images"
import { setNewPass } from "../../functions/db";
import { useNavigate  } from "react-router-dom";

export default function NewPass(props){
  const [load, setLoad] = useState(false);
  const [notif, setNotif] = useState(false);
  const [msgNotif, setMsgNotif] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [disabledInput, setDisabledInput]= useState(false)
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);
  
  let his = useNavigate ();
  useEffect(()=>{
    if(!props.auth) his("/")
  },[])

  var handleClick = e => {
    e.preventDefault();
    setLoad(true)
    setNewPass(props.user, pwd1, newNotif, setDisabledInput, redir,props.setUser);
  }
  var redir = () => {
    setLoad(false);
    his('/');
  }

  var newNotif = (n,msg) => {
    setNotif(n)
    setMsgNotif(msg)
  }
  useEffect(()=>{
    if(pwd1===pwd2 && pwd1 && !disabledInput)
      setDisabledButton(false)
    else setDisabledButton(true)
  },[pwd1,pwd2])

  return (
    <>
    {load?<Loader/>:""}
    <div className="position-absolute top-50 start-50 translate-middle w-100">
      <img src={LlaoLogoL} alt="Logo Llaollao El Salvador" className="position-absolute top-0 start-50 translate-middle" />
      <div className="row container-fluid">
        <div className="col-sm-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6 shadow-lg rounded-3 bg-white p-5">
          <form className="mx-md-5 mt-3 p-md-3 ">
            <h2 className="text-center">Cambio de Contraseña</h2>
            <p className="text-center">Luego del cambio de contraseña se le redirigirá al inicio de sesión</p>
            <div className="mb-3">
              <label htmlFor="PasswordInput" className="form-label">Ingrese nueva contraseña</label>
              <div className="input-group mb-3">
                <input type={showPwd1?"text":"password"} disabled={disabledInput} className="form-control" id="PasswordInput" value={pwd1} onChange={e=>setPwd1(e.target.value)} placeholder='Secret123'/>
                <span className="input-group-text" id="basic-addon1" onClick={()=>setShowPwd1(!showPwd1)}>
                  <i className={showPwd1?"bi-eye-slash ":"bi-eye"}/>
                </span>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="RepeatPasswordInput" className="form-label">Retipa la contraseña</label>
              <div className="input-group mb-3">
                <input type={showPwd2?"text":"password"} disabled={disabledInput} className="form-control" id="RepeatPasswordInput" value={pwd2} onChange={e=>setPwd2(e.target.value)} placeholder='Secret123'/>
                <span className="input-group-text" id="basic-addon1" onClick={()=>setShowPwd2(!showPwd2)}>
                  <i className={showPwd2?"bi-eye-slash ":"bi-eye"}/>
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button className="btn bg-main mb-1" disabled={disabledButton} onClick={e=>handleClick(e)}>Confirmar Cambio</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Alert active={notif} msg={msgNotif} close={setNotif}/>
    </>
  )
}